import * as React from 'react';
import { graphql } from 'gatsby';
import 'src/styles/prism-ghcolors.css';
import App from 'src/components/App';
import { ChapterBySlug2Query } from 'types/graphql-types';
import Markdown from 'src/components/Tutorials/Markdown';
import Footer from 'src/components/home/Footer';

interface Props {
  data: ChapterBySlug2Query;
  location: any;
  history: any;
}

const Page = ({ history, data: { mdx } }: Props) => {
  return (
    <App history={history}>
      <style jsx={true}>{`
        .content {
          @p: .pa38, .mhAuto;
          max-width: 85rem;
        }
      `}</style>
      <div className="content">{mdx?.body && <Markdown>{mdx.body}</Markdown>}</div>
      <Footer />
    </App>
  );
};

export default Page;

export const pageQuery = graphql`
  query ChapterBySlug2($id: String!) {
    mdx(id: { eq: $id }) {
      body
      fields {
        slug
      }
      excerpt(pruneLength: 140)
      frontmatter {
        title
        description
        pageTitle
      }
    }
  }
`;
